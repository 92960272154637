import * as axios from "axios";

let baseURL = "";

const applicationId = parseInt(process.env.REACT_APP_APPLICATION_ID || "-1");

if (process.env.NODE_ENV === "development") {
  baseURL = process.env.REACT_APP_SERVICE_LOCALHOST || "";
} else if (process.env.NODE_ENV === "production") {
  baseURL = process.env.REACT_APP_SERVICE_HOST || "";
}

const ServiceClient = new axios.Axios({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const exportObject = {
  appId: applicationId,
  client: ServiceClient,
};

export default exportObject;
