import React from "react";
import "@elastic/eui/dist/eui_theme_dark.css";
import { EuiProvider } from "@elastic/eui";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ConversationPage from "./pages/ConversationPage";
import ContentPage from "./pages/ContentPage";
import EditContentPage from "./pages/EditDocumentPage";
import FollowUpPage from "./pages/FollowUpPage";
const App = () => (
  <EuiProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/conversation" element={<ConversationPage />}></Route>
        <Route path="/content" element={<ContentPage />}></Route>
        <Route path="/follow-up" element={<FollowUpPage />}></Route>
        <Route
          path="/content/edit-document"
          element={<EditContentPage />}
        ></Route>
      </Routes>
    </BrowserRouter>
  </EuiProvider>
);
export default App;
