import {
  EuiButton,
  EuiLink,
  EuiPageSection,
  EuiMarkdownEditor,
  EuiPageTemplate,
  EuiText,
} from "@elastic/eui";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import service from "../service/service_host";
const EditContentPage = () => {
  const width = "75%";
  const [reRender, setReRender] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const documentId = queryParams.get("documentId") || undefined;
  const navigator = useNavigate();
  const [value, setValue] = useState<string>("initialContent");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  useEffect(() => {
    service.client
      .post(
        `/web-data/read`,
        JSON.stringify({ link_id: documentId, app_id: service.appId })
      )
      .then((response) => {
        return JSON.parse(response.data).document || "";
      })
      .then((data) => {
        setValue(data);
      })
      .catch((error) => {
        // TODO handle Failure of fetching data
        console.log(error);
      })
      .finally(() => {
        setReRender(false);
      });
  }, [reRender, documentId]);

  const updateDocument = () => {
    setIsSaving(true);
    service.client
      .put(
        `/web-data/`,
        JSON.stringify({
          link_id: documentId,
          document: value,
          app_id: service.appId,
        })
      )
      .then((response) => {
        // TODO Show updation success data
        setReRender(true);
      })
      .catch((error) => {
        // TODO handle Failure of fetching data
        console.log(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <>
      <EuiPageTemplate grow>
        <Header />
        <EuiPageSection>
          <EuiText textAlign="center">
            <h1>Edit Source</h1>
          </EuiText>
        </EuiPageSection>
        <EuiPageSection restrictWidth={width}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <EuiLink href={documentId} target="_blank">
              View Page
            </EuiLink>
            <div style={{ width: "25px" }}></div>
            <EuiButton
              isLoading={isSaving}
              color="success"
              onClick={updateDocument}
            >
              Save
            </EuiButton>
            <div style={{ width: "25px" }}></div>
            <EuiButton
              color="danger"
              onClick={() => {
                navigator("/content");
              }}
            >
              Discard
            </EuiButton>
          </div>
        </EuiPageSection>
        <EuiPageSection restrictWidth={width} color={"transparent"}>
          <EuiMarkdownEditor
            aria-label="Markdown editor"
            placeholder="Add your content here..."
            value={value}
            onChange={setValue}
            readOnly={false}
            height={800}
            initialViewMode="editing"
          />
        </EuiPageSection>
      </EuiPageTemplate>
    </>
  );
};

export default EditContentPage;
