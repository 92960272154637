import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import {
  EuiPageSection,
  EuiPageTemplate,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import service from "../service/service_host";

const ConversationPage = () => {
  const width = false;
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState();
  useEffect(() => {
    service.client
      .post(
        "/conversation/chats",
        JSON.stringify({
          app_id: service.appId,
        })
      )
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        setConversations(data);
      });
  }, []);

  const buildConversationList = (conversations: any[]) => {
    return conversations.map((conversation, idx) => {
      const style = {
        padding: "8px",
        backgroundColor: idx % 2 === 0 ? "#80808036" : undefined,
      };
      return (
        <EuiText
          key={idx}
          style={style}
          onClick={() => {
            handleConversationClick(conversation);
          }}
        >
          {conversation.name ? conversation.name : `Unknown ${conversation.id}`}
        </EuiText>
      );
    });
  };

  const handleConversationClick = (conversation: any) => {
    service.client
      .post(
        `/conversation/chat-detail`,
        JSON.stringify({
          chat_id: conversation.id,
          app_id: service.appId,
        })
      )
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        setConversation(data);
      });
  };

  const buildConversation = (conversation: any) => {
    return (
      <>
        <EuiText>
          <h2>
            {conversation.name
              ? conversation.name
              : `Unknown ${conversation.id}`}
          </h2>
          <p>{conversation.email}</p>
        </EuiText>
        <EuiSpacer size="xl" />
        <div>
          {conversation.messages.map((message: any, idx: number) => {
            const isVisitor = message.userType === "visitor";
            const style = {
              margin: "8px",
              padding: "12px",
              borderRadius: "4px",
              borderColor: "#ccc",
              backgroundColor: isVisitor ? "#f1f1f1" : "#ddd",
            };
            return (
              <EuiText key={idx} style={style}>
                <Markdown remarkPlugins={[remarkGfm]}>
                  {message.message}
                </Markdown>
                <span
                  style={{
                    color: "grey",
                  }}
                >
                  {new Date(message.createdAt).toLocaleTimeString("en-IN")}
                </span>
              </EuiText>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      <Header />
      <EuiPageTemplate grow={true}>
        <EuiPageTemplate.Sidebar sticky paddingSize="none">
          {buildConversationList(conversations)}
        </EuiPageTemplate.Sidebar>
        <EuiPageSection
          restrictWidth={width}
          color={"transparent"}
          alignment={conversation ? "top" : "center"}
          grow={true}
        >
          {conversation ? (
            buildConversation(conversation)
          ) : (
            <EuiText>No chat Selected</EuiText>
          )}
        </EuiPageSection>
      </EuiPageTemplate>
    </>
  );
};

export default ConversationPage;
