import { useNavigate } from "react-router-dom";
import {
  EuiButton,
  EuiCard,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiPageHeader,
  EuiPageSection,
  EuiPageTemplate,
  EuiGlobalToastList,
} from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import { useEffect, useState } from "react";

interface ICredential {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [credential, setCredential] = useState<ICredential>({
    email: "",
    password: "",
  });
  const [toasts, setToasts] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    // TODO: Implement login logic
    const token = localStorage.getItem("token");
    if (token === "admin") {
      navigate("/");
    } else if (token) {
      localStorage.removeItem("token");
      const toast = {
        title: "Invalid Token Login again",
        color: "danger",
        iconType: "error",
      };
      setToasts([...toasts, toast]);
    }
  }, [navigate, toasts]);

  const removeToast = (removedToast: Toast) => {
    setToasts((toasts) =>
      toasts.filter((toast) => toast.id !== removedToast.id)
    );
  };

  const handleChangeCredential = (e: any) => {
    setCredential({
      ...credential,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    // TODO: Implement login logic
    if (
      credential.email === "admin@admin.com" &&
      credential.password === "admin@123"
    ) {
      localStorage.setItem("token", "admin");
      localStorage.setItem("username", "Admin");
      navigate("/");
    } else {
      const toast = {
        title: "Invalid Credential",
        color: "danger",
        iconType: "error",
      };
      setToasts(toasts.concat(toast));
    }
  };

  return (
    <>
      <EuiPageTemplate>
        <EuiPageHeader
          paddingSize="l"
          restrictWidth={false}
          bottomBorder={true}
          pageTitle="Admin Console"
        />
        <EuiPageSection
          restrictWidth={false}
          alignment={"center"}
          className="login-container"
          color={"plain"}
          grow={true}
        >
          <EuiCard
            title="Login"
            paddingSize="xl"
            betaBadgeProps={{
              label: "Admin",
            }}
            icon={<EuiIcon size="xxl" type="discuss" />}
          >
            <EuiForm component="form">
              <EuiFormRow label="Email">
                <EuiFieldText
                  name="email"
                  type="email"
                  onChange={handleChangeCredential}
                  value={credential.email}
                />
              </EuiFormRow>
              <EuiFormRow label="Password">
                <EuiFieldText
                  name="password"
                  type="password"
                  onChange={handleChangeCredential}
                  value={credential.password}
                />
              </EuiFormRow>

              <EuiButton type="button" fill onClick={handleClick}>
                Login
              </EuiButton>
            </EuiForm>
          </EuiCard>
          <EuiGlobalToastList
            toasts={toasts.map((toast, index) => {
              toast.id = index.toString();
              return toast;
            })}
            dismissToast={removeToast}
            toastLifeTimeMs={5000}
          />
        </EuiPageSection>
      </EuiPageTemplate>
    </>
  );
};

export default LoginPage;
