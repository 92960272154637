import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPageSection,
  EuiPageTemplate,
} from "@elastic/eui";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const width = false;
  return (
    <>
      <EuiPageTemplate>
        <Header />
        <EuiPageSection
          restrictWidth={width}
          color={"transparent"}
          alignment={"center"}
          grow={true}
        >
          <EuiFlexGroup
            gutterSize="xl"
            justifyContent="spaceAround"
            alignItems="stretch"
          >
            <EuiFlexItem>
              <EuiCard
                icon={<EuiIcon size="xxl" type="database" />}
                title="Content Source"
                onClick={() => {
                  navigate("/content");
                }}
                betaBadgeProps={{
                  label: "Web data",
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                icon={<EuiIcon size="xxl" type="discuss" />}
                title="Visitor conversations"
                onClick={() => {
                  navigate("/conversation");
                }}
                betaBadgeProps={{
                  label: "Logs",
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                icon={<EuiIcon size="xxl" type="dashboardApp" />}
                title="Follow up Question"
                onClick={() => {
                  navigate("/follow-up");
                }}
                betaBadgeProps={{
                  label: "Moderation",
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageSection>
      </EuiPageTemplate>
    </>
  );
};

export default HomePage;
