import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiConfirmModal,
  EuiFieldText,
  EuiFormLabel,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPageSection,
  EuiPageTemplate,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import service from "../service/service_host";

interface IContentRow {
  sno: number;
  question: string;
  id: string;
}

const FollowUpPage = () => {
  const width = "75%";
  const [reRender, setReRender] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [contents, setContents] = useState<IContentRow[]>([]);
  const [isAddQuestionModalVisible, setIsAddQuestionModalVisible] =
    useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [deleteSelection, setDeleteSelection] = useState<IContentRow>();
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const openAddSourceModal = () => setIsAddQuestionModalVisible(true);
  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const onDeleteRow = (row: IContentRow) => {
    setDeleteSelection(row);
    openDeleteModal();
  };

  const onSave = () => {
    setIsAdding(true);
    service.client
      .post(
        "/follow-up/",
        JSON.stringify({ question: question, app_id: service.appId })
      )
      .then((response) => {
        if (response.status !== 200) {
          throw response;
        }
        setReRender(true);
        setIsAdding(false);
        setIsAddQuestionModalVisible(false);
        setQuestion("");
      })
      .catch((error) => {
        console.log("Error saving data", error);
        setIsAdding(false);
      });
  };
  const onCancel = () => {
    setQuestion("");
    setIsAddQuestionModalVisible(false);
  };

  const columns: EuiBasicTableColumn<IContentRow>[] = [
    {
      field: "sno",
      name: "S.No",
      truncateText: false,
      width: "100px",
    },
    {
      field: "question",
      name: "Question",
      truncateText: false,
      width: "50vw",
    },
    {
      name: "Actions",
      actions: [
        {
          name: "Delete",
          description: "Delete this Question",
          type: "icon",
          color: "danger",
          icon: "trash",
          onClick: onDeleteRow,
        },
      ],
    },
  ];
  const deleteModalId = useGeneratedHtmlId();
  const confirmDelete = async () => {
    try {
      await service.client.delete(`/follow-up/`, {
        data: JSON.stringify({
          id: deleteSelection?.id,
          app_id: service.appId,
        }),
      });
    } catch (e) {
      //TODO Implement error message to UI while deleting
      console.log(e);
    }
    setDeleteSelection(undefined);
    setIsDeleteModalVisible(false);
    setReRender(true);
  };
  const cancelDelete = () => {
    setDeleteSelection(undefined);
    setIsDeleteModalVisible(false);
  };
  useEffect(() => {
    const temp: IContentRow[] = [];
    service.client
      .post(
        "follow-up/all",
        JSON.stringify({
          app_id: service.appId,
        })
      )
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        data.questions.forEach((row: any, idx: number) => {
          temp.push({
            sno: idx + 1,
            question: row.question,
            id: row.id,
          });
        });
      })
      .catch((error) => {
        //TODO Implement error message to UI while fetching
        console.error("Error fetching data", error);
      })
      .finally(() => {
        setContents(temp);
        setReRender(false);
      });
  }, [reRender]);
  return (
    <>
      <EuiPageTemplate>
        <Header />
        <EuiPageSection>
          <EuiText>
            <h1>Follow Up</h1>
          </EuiText>
        </EuiPageSection>
        <EuiPageSection restrictWidth={width} color={"transparent"}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <EuiButton
              isLoading={isAdding}
              color="success"
              iconType={"globe"}
              onClick={openAddSourceModal}
            >
              Add
            </EuiButton>
          </div>
          <EuiBasicTable
            tableCaption="Follow Up Questions"
            items={contents}
            columns={columns}
          />
        </EuiPageSection>
      </EuiPageTemplate>
      {isDeleteModalVisible && (
        <EuiConfirmModal
          title="Are you confirm Delete?"
          titleProps={{ id: deleteModalId }}
          buttonColor="danger"
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        ></EuiConfirmModal>
      )}
      {isAddQuestionModalVisible && (
        <EuiModal
          onClose={onCancel}
          initialFocus="[name=popswitch]"
          style={{ width: "50%" }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>Add Follow Up Question</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFormLabel>Question</EuiFormLabel>
            <EuiFieldText
              name="question"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={onCancel} color="danger">
              Cancel
            </EuiButton>
            <EuiButton isLoading={isAdding} onClick={onSave} fill>
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  );
};

export default FollowUpPage;
