import { useEffect, useState } from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { useNavigate } from "react-router-dom";

const HeaderUserMenu = () => {
  const name: string = localStorage.getItem("username") || "John Username";

  const userPopoverId = useGeneratedHtmlId({ prefix: "userPopover" });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    // TODO: Implement login verification logic
    const token = localStorage.getItem("token");
    if (token !== "admin") {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      navigate("/login");
    }
  }, [navigate]);
  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };
  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={name} size="s" />
    </EuiHeaderSectionItemButton>
  );

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="m"
    >
      <div style={{ width: 300 }}>
        <EuiFlexGroup gutterSize="m" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={name} size="xl" />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>{name}</p>
            </EuiText>
            <EuiSpacer size="m" />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={handleLogout}>Log out</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
};

const Header = (props: { theme?: any }) => {
  const navigate = useNavigate();
  return (
    <EuiHeader position="static" theme={props.theme}>
      <EuiLink>
        <EuiHeaderSection
          onClick={() => {
            navigate("/");
          }}
        >
          <EuiHeaderSectionItem>
            <EuiHeaderLogo iconType={"discuss"}>KALI AI</EuiHeaderLogo>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiLink>
      <EuiHeaderSection side="right">
        {/* // TODO Theme button on header */}
        {/* <EuiHeaderSectionItem>
            <EuiSwitch
              label={"Theme"}
              checked={theme === "dark"}
              onChange={(e) => setTheme(e.target.checked ? "dark" : "default")}
            />
          </EuiHeaderSectionItem> */}
        <EuiHeaderSectionItem>
          <HeaderUserMenu />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
};

export default Header;
